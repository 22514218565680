/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');

.auth-bg {
  background-color: white;  
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Numans', sans-serif;
}

.container {
  height: 100%;
  align-content: center;
}

.card {
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: white;
}

.marginTop {
  margin-top: 75px !important;
}

.social_icon span {
  font-size: 30px;
  margin-left: 10px;
  color: var(--theme-color) !important;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: 10px;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.form-group-btn {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.form-group-btn input {
  background-color: #FFC312;
}

.input-group-prepend {
  display: flex;
  margin-right: -4px;

}

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-group-prepend span {
  width: 50px;
  background-color: #FFC312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;

}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #FFC312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signUp-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 70px;
}

.form-wrapper {
  margin-top: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px #555;
  background-color: #ffffff;
}

.form-wrapper h1 {
  text-align: center;
  width: 100%;
  color: #111;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.login-form-control {
  margin-bottom: 1rem;
}

.login-form-control input,
.login-form-control label {
  display: block;
}

.login-form-control input,
.login-form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.login-form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .login-form-control {
  min-width: 15rem;
  flex: 1;
}

.login-button {
  font: inherit;
  background-color: #73215d;
  color: white;
  border: 1px solid #73215d;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #73215d;
  border-color: #73215d;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #ea8b7c
}

.password-val {
  text-align: center;
  background-color: rgb(255 156 206);
  color: white;
  padding: 15px;
  /* margin-top: 30px; */
  border-radius: 5px;
  width: 350px;
}

.clear {
  clear: both;
}

.google-login > button {
  width: 100%;
}
.google-login > button > div {
  position: absolute;
}
.google-login > button > span {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
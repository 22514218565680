.portal-wrapper {
  padding: 150px 0 70px;
  background-color: #f6f9fc;
  min-height: calc(100vh - 100px);
}

.user-info h3 {
  margin: 4px 0;
}
.user-info > span {
  color: #8898aa;
}
.user-info a {
  color: #5f9f72;
  font-weight: bold;
}
.user-info button {

}

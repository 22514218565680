.carousel-img {
  height: 200px;
  display: block;
  width: 100%;
}
.carousel-wrapper {
  position: relative;
}
.carousel-prev-btn, .carousel-next-btn {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  font-size: 20px;
  cursor: pointer;
  color: white !important;
}
.carousel-next-btn {
  right: 16px;
}
.carousel-prev-btn {
  left: 16px;
}

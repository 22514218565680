.mb-10-px {
  margin-bottom: 10px;
}
.ml-2 {
  margin-left: 8px;
}
.pt-5 {
  padding-top: 3rem;
}
.listing-bg {
  padding: 40px 10%;
  margin-top: 70px;
}

.listing-title {
  font-size: 30px !important;
  color: rgb(7, 191, 197) !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.marginTop {
  margin-top: 70px;
}

.map-box {
  width: 50%;
  margin-left: 30px;
  height: 253px;
  background-color: aliceblue;
}

.metrics-select {
  margin-top: 35px;
  width: 55%;
}

.metrics-select .cars {
  color: #0998dc;
  font-weight: 600;
  margin-bottom: 5px;
}

.metrics-select .ant-select {
  border: 0;
}

.totalArea {
  background: white;
  border-radius: 20px;
  margin-left: 60px;
  margin-top: 82px;
  width: 25%;
  height: 30px;
  padding: 39px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.totalArea .areaText {
  font-size: 17px;
  color: #004d9c;
}

.uploadImg {
  width: 10%;
  height: auto;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.map-relative {
  position: relative;
}

.mapboxgl-control-container {
  position: absolute !important;
  right: 163px !important;
  top: 0px !important;
}

.form-group {
  justify-content: flex-start !important;
}

.multi-preview img {
  width: 20%;
  border-radius: 20px;
  margin-right: 20px;
}

.field-title {
  color: #0998dc;
  font-weight: 600;
  margin-bottom: 5px;
}

.addlisting-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.imageUpload-input {
  display: none;
}

.imageUpload-field {
  cursor: pointer;
}

.imageUpload-icon {
  margin: 0 10px;
  width: 70px !important;
  height: 5 0px !important;
}

.listing-craete-btn {
  margin-top: 20px;
}

.listing-craete-btn input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #12a7ff;
  color: white;
  width: 100px;
}

.listing-craete-btn input:hover {
  background-color: white;
  color: #12a7ff;
  border: 1px solid #12a7ff;
}


/*display listing */
.loadingText {
  font-size: 18px;
  color: #2892ff;
  font-weight: 500;
}

.listing-body-title {
  margin-top: 20px !important;
  font-size: 30px;
  font-weight: 600;
  color: #5c6eb4;
  letter-spacing: 6px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/*----  Main Style  ----*/
#cards_landscape_wrap-2 {
  text-align: center;
  background: white;
}

#cards_landscape_wrap-2 .container {
  padding-bottom: 20px; 
}

#cards_landscape_wrap-2 a {
  text-decoration: none;
  outline: none;
}

#cards_landscape_wrap-2 .card-flyer {
  border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .image-box {
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.50);
  border-radius: 5px;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container .edit-btn button:hover {
  width: 50px;
  height: 25px;
  border: 1px solid #7ec1d0;
  border-radius: 3px;
  background-color: #67a5ad;
  color: white;
  font-weight: 600;
  font-size: 12px;
}

#cards_landscape_wrap-2 .card-flyer .image-box img {
  -webkit-transition: all .9s ease;
  -moz-transition: all .9s ease;
  -o-transition: all .9s ease;
  -ms-transition: all .9s ease;
  width: 100%;
  height: 200px;
}

#cards_landscape_wrap-2 .card-flyer:hover .image-box img {
  opacity: 0.7;
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
}

.listingHeight {
  position: relative;
  height: 445px;
  overflow: auto;
}

#cards_landscape_wrap-2 .card-flyer .text-box {
  text-align: left;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container {
  padding: 30px 18px 10px 18px
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container .edit-btn {
  position: absolute;
  bottom: 10px;
  right: 13px;
  display: flex;
  justify-content: end;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container .edit-btn a {
  border: 1px solid #0785f0;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 13px;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container .edit-btn button {
  width: 50px;
  height: 25px;
  border: 1px solid #7ec1d0;
  border-radius: 3px;
  color: #139ef0;
  font-weight: 600;
  font-size: 12px;
}

#cards_landscape_wrap-2 .card-flyer .text-box .text-container h6 {
  text-align: center;
}

#cards_landscape_wrap-2 .card-flyer {
  background: #FFFFFF;
  margin-top: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.40);
}

#cards_landscape_wrap-2 .card-flyer:hover {
  background: #fff;
  box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.50);
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  margin-top: 50px;
}

#cards_landscape_wrap-2 .card-flyer .text-box p {
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #000000;
}

#cards_landscape_wrap-2 .card-flyer .text-box h6 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Roboto Black', sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
}
.upload-images {
  background: white;
  padding: 1rem 1rem 0.5rem;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.list-title {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Black",sans-serif;
  letter-spacing: 1px;
  color: #00acc1;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.listing-detail-line {

}
.listing-detail-line span {
  width: 50%;
}
.listing-detail-line span:first-child { 
  font-weight: 500;
}
.listing-detail-line span:last-child {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.spin-load {
  top: 50%;
  left: 50%;
}
.listing-skeleton-img {
  width: 100% !important;
  height: 200px !important;
}

.search-view {
  padding-top: 150px;
  padding-bottom: 70px;
}
.form-item {
  margin-bottom: 0.5rem !important;
}
.form-item .ant-form-item-label label {
  height: auto !important;
}
.scroll-col {
  max-height: 800px;
  overflow-y: auto;
}
.scroll-col::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}
.listing-h-card .ant-card-body {
  padding: 16px;
  display: flex;
}
.listing-h-card .card-details h6 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto Black",sans-serif;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.nearByResultsLimit {
  width: 80px;
}

:root {
  --theme-color: #1890ff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-skyblue {
  color: var(--theme-color);
}
.bg-skyblue {
  background-color: var(--theme-color) !important;
}
.stipe-token-page {
  min-height: calc(100vh - 102px) !important;
  align-items: center;
  justify-content: center;
}

.profile-wrapper {
  padding: 150px 0 100px;
}
.spin-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.5);
}
